._card {
    width: 100%;
    padding: 11px 32px 10px 32px;
    border-radius: 10px;
    margin-bottom: 20px;

    &.gray-bg {
        background: $color-e6;
    }

    &.shadow {
        box-shadow: -3px 11px 13px 0 rgba(0, 0, 0, 0.15);
    }

    &.white-bg {
        background: $color-white;
    }

    &.border-c3 {
        border: 1px solid $color-c3;
    }
}

._card-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;

    h4 {
        font-size: 24px;
        font-weight: 600;
        margin: 0;
    }
}

._card-footer {
    padding: 20px 0;
}


._card1 {
    width: 100%;
    border: 1px solid $color-ae;
    border-radius: 10px;

    ._header {
        width: 100%;
        background: $color-dark-gray;
        padding: 12px 22px;
        color: $color-white;
        border-radius: 10px 10px 0 0;
        display: flex;
        justify-content: space-between;

        h6 {
            font-size: 24px;
            font-weight: 600;
            color: $color-white;
            margin: 0;
        }
    }
}