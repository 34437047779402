@import './colors';
// TYPEFACE

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $color-light-black;
}

h1 {
    font-size: 30px;
    font-weight: 700;
}

h2 {
    font-size: 22px;
    font-weight: 600;
}

h3 {
    font-size: 18px;
    font-weight: 600;
}

h4 {
    font-size: 16px;
    font-weight: 600;
}

p {
    font-size: 16px;
}