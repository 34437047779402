// Variable overrides first
$primary: #ce0e2d;
$enable-shadows: true;
$prefix: "mx-";
/* You can add global styles to this file, and also import other style files */
@import "../node_modules/bootstrap/scss/bootstrap";
@import "../node_modules/ngx-bootstrap/datepicker/bs-datepicker.css";
@import "../node_modules/ngx-easy-table/style.scss";
@import './styles/colors';
@import './styles/buttons';
@import './styles/form.control';
@import './styles/tabs';
@import './styles/alerts';
@import './styles/typeface';
@import "~@ng-select/ng-select/themes/default.theme.css";
@import './styles/ng-select';
@import './src/styles/_docs.scss';
@import './styles/core';
@import './styles/card';
@import './styles/pagination';
@import './styles/date-picker';
@import './styles/popover';
@import "./styles/ngx-easy-table";
@import "~bootstrap-icons/font/bootstrap-icons.css";

body {
    min-height: 100vh;
    position: relative;
    font-weight: 400;
    font-family: proxima-nova, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: none;
    line-height: 1.57;
    background: $color-light-gray;
    font-size: 16px;
}
html,
body {
    height: 100%;
}
.ng-input
{
    border: #ccc solid 1px;
    border-radius: 10px;

}

.notAllow{
    cursor: not-allowed;
}