@import './colors';
@import 'src/styles/mixins/breakpoints';

// Select Dropdown

.ng-select.white-bg {
    .ng-select-container {
        background: $color-white !important;
    }
}

.ng-select {
    // @include breakpoint-range($max : sm) {
    //     margin-bottom: 15px;
    // }

    .ng-clear-wrapper {
        width: 20px;

        .ng-clear {
            font-weight: 500;
            color: $color-red;
        }
    }

    .ng-select-container {
        min-height: 67px;
        background-color: $color-f7;
        border: none;
        border-radius: 10px !important;

        .ng-value-container {
            padding: 0 20px;

            .ng-placeholder {
                font-size: 16px;
                color: $color-70;
                position: absolute;
                top: 10px;
                left: 20px;
            }
        }
    }

    .ng-has-value {
        .ng-placeholder {
            display: block;
        }
    }

    &.ng-select-single {
        .ng-select-container {
            .ng-value-container {
                padding-top: 18px;
                font-size: 22px;

                .ng-input {
                    padding-left: 20px;
                    padding-right: 65px;
                    height: 100%;
                    top: 0;

                    &>input {
                        height: 100%;
                        padding-top: 15px;
                        box-sizing: border-box;
                    }
                }

            }
        }
    }

    .ng-arrow-wrapper {
        width: 60px;
        background: $color-medium-gray;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        border: #ccc solid 1px;
        border-radius: 0px 10px 10px 0px;

        .ng-arrow {
            background: url('/assets/images/icons/drop-down-arrow.svg') no-repeat;
            width: 15px !important;
            height: 8px !important;
            border: none;
        }
    }

    &.ng-select-opened {
        .ng-select-container {
            border: none;
            background: $color-f7;

            .ng-arrow {
                transform: rotate(-180deg);
                top: 0;
                border: none;
            }
        }
    }

}

.ng-dropdown-panel.ng-select-bottom {
    margin-top: 5px;
}

.ng-dropdown-panel,
.ng-dropdown-panel.ng-select-bottom {
    border: 1px solid $color-gray;
    border-radius: 5px;
    @include box-shadow();
    overflow: hidden;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    padding: 10px 20px;
    font-size: 16px;

    &.ng-option-marked,
    &.ng-option-selected {
        background-color: $color-light-gray;

        &:hover {
            background-color: $color-light-gray;
        }
    }
}

.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
    border: none;
    outline: none !important;
    @include box-shadow();
}

.ng-dropdown-panel.ng-select-top {
    margin-bottom: 5px;
}

.ng-select.ng-select-filtered .ng-placeholder {
    display: block !important;
}