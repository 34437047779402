$color-red: #ce0e2d;
$color-dark-gray: #575656;
$color-light-black: #4c4d4b;
$color-blue: #5469ae;
$color-medium-black: #202020;
$color-white: #fff;
$color-light-gray: #f8f8f8;
$color-medium-gray: #e8e8e8;
$color-gray: #b1b1b1;
$color-gray-b7 : #b7b7b7;
$color-gray-b1 : #b1b1b1;
$color-black: #000;
$color-light-sea-green :#dbf4f2;
$color-teal: #008380;
$color-f7 : #f7f7f7;
$color-70: #707070;
$color-c9: #C9C9C9;
$color-c0: #c0c0c0;
$color-ae: #aeaeae;
$color-de : #dedede;
$color-blue-dark-1 : #182A4D;
$color-green : #23af23;
$color-e6 : #e6e6e6;
$color-c3 : #c3c3c3;


/// 
$color-orange : #ed9823;
$color-maroon : #c62941;
$color-egg-blue : #2390ed;