@import '/src/styles/mixins/breakpoints';

.pagination-v2 {
    --mx-pagination-font-size: 24px;
    --mx-pagination-color: $color-blue;
    --mx-pagination-padding-y: 0;
    --mx-pagination-padding-x: 0;
    --mx-pagination-border-radius: 0;
    --mx-pagination-focus-box-shadow: none;
    --mx-pagination-active-bg: $color-white;
    --mx-pagination-active-border-color: $color-light-gray;

    &>.page-item {
        width: 54px;
        height: 54px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $color-c0;
        margin-left: 10px;
        overflow: hidden;

        @include breakpoint-range($max : sm) {
            width: 42px;
            height: 42px;
        }



        .page-link {
            color: #8898A9;
            border: none !important;
            margin-left: 0 !important;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            @include breakpoint-range($max : sm) {
                font-size: 16px;
            }
        }

        &.active {
            background: $color-red;
            border-color: $color-red;

            .page-link {
                color: $color-white !important;
            }
        }

        &.pagination-prev {
            text-indent: -1000000px;

            .page-link::before,
            .page-link::after {
                color: #8898A9;
                border-right: 2px solid currentcolor;
                border-bottom: 2px solid currentcolor;
                content: '';
                position: absolute;
                width: 12px;
                height: 12px;
                transform: rotate(135deg);
                margin-right: -3px;

                @include breakpoint-range($max : sm) {
                    width: 10px;
                    height: 10px;
                    border-right: 1px solid currentcolor;
                    border-bottom: 1px solid currentcolor;
                }
            }

            &.disabled {
                opacity: 0.5;
            }
        }

        &.pagination-next {
            text-indent: -200000px;

            .page-link::before,
            .page-link::after {
                color: #8898A9;
                border-right: 2px solid currentcolor;
                border-bottom: 2px solid currentcolor;
                content: '';
                position: absolute;
                width: 12px;
                height: 12px;
                transform: rotate(-45deg);
                margin-left: -3px;

                @include breakpoint-range($max : sm) {
                    width: 10px;
                    height: 10px;
                    border-right: 1px solid currentcolor;
                    border-bottom: 1px solid currentcolor;
                }
            }

            &.disabled {
                opacity: 0.5;
            }
        }
    }
}