.popover {
    position: absolute;
    --mx-popover-bg: #fbe4e8;
    --mx-popover-border-color: #fbe4e8;
    --mx-popover-box-shadow: none;

    .popover-header {
        background: #fbe4e8;
    }

    .popover-arrow {
        position: absolute;
    }
}

.popover-custom-template {
    width: 100%;
    font-size: 16px;
}

.tooltip {
    position: absolute;

    .tooltip-arrow {
        position: absolute;
    }
}

.tooltip-primary {
    &.top {
        .tooltip-arrow {
            &::before {
                border-top-color: $color-red;
            }
        }
    }

    &.start {
        .tooltip-arrow {
            &::before {
                border-left-color: $color-red;
            }
        }
    }

    &.bottom {
        .tooltip-arrow {
            &::before {
                border-bottom-color: $color-red;
            }
        }
    }


    &.end {
        .tooltip-arrow {
            &::before {
                border-right-color: $color-red;
            }
        }
    }

    .tooltip-inner {
        background: $color-red;
    }
}