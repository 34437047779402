// Form Control
@import 'src/styles/mixins/breakpoints';
@import '/src/styles/colors';
.form-control {
    height: 45px;
    border: 1px solid $color-gray;
    border-radius: 5px;
    color: $color-light-black;
    padding: 0 17px;

    &:focus {
        box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.21);
        // @include box-shadow(2px, 2px, 6px, 0, $color-black, 0.21);
        border: solid 1px $color-blue;
    }

    &.error,
    &:invalid,
    &.ng-invalid,
    &.ng-invalid.ng-touched,
    &.ng-invalid:focus {
        // @include box-shadow();
        color: $color-red;
        border-color: $color-red;
        box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.21);

    }

    &::placeholder {
        color: $color-gray;
    }

    &:disabled {
        background: $color-medium-gray;

    }

    @include breakpoint-range($max : sm) {
        margin-bottom: 15px;
    }

}

// Text Area
textarea.form-control {
    padding: 17px;
    min-height: 100px;

    @include breakpoint-range($max : sm) {
        margin-bottom: 15px;
    }
}

.input-container {
    width: 100%;
    display: block;
    position: relative;

    &.white {
        .input {
            background: $color-white;
            border: #ccc solid 1px;
            border-radius: 10px;
        }
    }

    &.success {

        &::before {
            content: '';
            position: absolute;
            right: 10px;
            top: 50%;
            background: url('/assets/images/icons/check2.svg') no-repeat;
            transform: translateY(-50%);
            width: 24.5px;
            height: 17px;
            background-size: 100%;
            filter: invert(52%) sepia(51%) saturate(4166%) hue-rotate(73deg) brightness(82%) contrast(92%);
        }

        .input {
            border: 1px solid $color-green;
        }
    }

    .input {
        height: 67px;
        width: 100%;
        background: $color-f7;
        border-radius: 10px;
        border: none;
        padding: 20px 20px 0 20px;
        font-size: 22px;

        &::placeholder {
            color: transparent;
        }

        &:focus {
            border: solid 0.5px $color-blue;
        }

        &:focus-visible {
            outline: none !important;
        }

        &.error,
        &:invalid,
        // &.ng-invalid,
        &.ng-invalid.ng-touched,
        &.ng-invalid:focus {
            color: $color-red;
            border: 1px solid $color-red !important;
        }

        &:disabled {
            background: $color-c9;
        }

    }

    .inputText {
        height: 67px;
        width: 100%;
        padding: 20px 20px 0 20px;
        font-size: 22px;
        background: $color-white;
        border: #ccc solid 1px;
        border-radius: 10px;

       

    }

    .icon-container {
        width: 63px;
        display: flex;
        height: 67px;
        border-radius: 10px 0 0 10px;
        background: $color-red;
        align-items: center;
        justify-content: center;

        img {
            max-width: 30px;
        }
    }

    .control {
        position: relative;
        width: calc(100% - 63px);

        .input {
            border-radius: 0 10px 10px 0;
        }
    }

    .label {
        position: absolute;
        left: 20px;
        top: 10px;
        font-size: 16px;
        color: $color-70;
    }

    .input-dropdown {
        position: absolute;
        top: 0;
        right: 0;
        width: 60px;
        height: 67px;
        background-color: #e2dfdf;
        border-radius: 0 10px 0 0;
        background-image: url("/assets/images/icons/drop-down-arrow.svg");
        background-position: center center;
        background-repeat: no-repeat;
    }

}


.value-conatiner {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .value-bg {
        width: 80%;
        background: $color-c9;
        border-radius: 0 0 10px 10px;
        padding: 10px 17px;

        .value-label {
            font-size: 16px;
            color: #707070;
            text-transform: uppercase;
            line-height: 16px;
        }

        .value {
            font-size: 22px;
            color: #231f20;
            line-height: 22px;
        }
    }
}